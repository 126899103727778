$(window).ready(function () {
  // Page Preloader
  $('#preloader').delay(350).fadeOut(function () {
    $('body').delay(350).css({ 'overflow': 'visible' });
  });
});

var __init = function () {

  "use strict";
  var SELECTORS = {
    SETTINGS_MENU: '#settings-menu'
  };

  var RIGHT_PANEL_OPEN = 'chat-view';
  var LEFT_PANEL_COLLAPSED = 'leftpanel-collapsed';
  var LEFT_PANEL_SHOW = 'leftpanel-show';
  var MENU_COLLAPSED = 'menu-collapsed';
  var NAV_ACTIVE = 'nav-active';
  var CHAT_RELATIVE_VIEW = 'chat-relative-view';

  var body = $('body');

  // Toggle Left Menu
  $('.leftpanel .nav-parent > a').on('click', function () {

    var parent = $(this).parent();
    var sub = parent.find('> ul');

    // Dropdown works only when leftpanel is not collapsed
    if (!body.hasClass(LEFT_PANEL_COLLAPSED)) {
      if (sub.is(':visible')) {
        sub.slideUp(200, function () {
          parent.removeClass(NAV_ACTIVE);
        });
      } else {
        closeVisibleSubMenu();
        parent.addClass(NAV_ACTIVE);
      }
    }
    return false;
  });

  function closeVisibleSubMenu() {
    $('.leftpanel .nav-parent').each(function () {
      var t = $(this);
      if (t.hasClass(NAV_ACTIVE)) {
        t.find('> ul').slideUp(200, function () {
          t.removeClass(NAV_ACTIVE);
        });
      }
    });
  }

  $('.toggle').toggles({ on: true });
  $('.toggle-chat1').toggles({ on: false });
  $('.tooltips').tooltip({ container: 'body' });
  $('.popovers').popover();

  // Close Button in Panels
  $('.panel .panel-close').click(function () {
    $(this).closest('.panel').fadeOut(200);
    return false;
  });

  // Minimize Button in Panels
  $('.minimize').click(function () {
    var t = $(this);
    var p = t.closest('.panel');
    if (!$(this).hasClass('maximize')) {
      p.find('.panel-body, .panel-footer').slideUp(200);
      t.addClass('maximize');
      t.html('&plus;');
    } else {
      p.find('.panel-body, .panel-footer').slideDown(200);
      t.removeClass('maximize');
      t.html('&minus;');
    }
    return false;
  });

  // Menu Toggle
  $('.menutoggle').click(function () {
    var bodypos = body.css('position');
    if (bodypos != 'relative') {
      if (!body.hasClass(LEFT_PANEL_COLLAPSED)) {
        body.addClass(LEFT_PANEL_COLLAPSED);
        $('.nav-bracket ul').attr('style', '');
        $(this).addClass(MENU_COLLAPSED);
      } else {
        body.removeClass(LEFT_PANEL_COLLAPSED + ' ' + RIGHT_PANEL_OPEN);
        $('.nav-bracket li.active ul').css({ display: 'block' });
        $(this).removeClass(MENU_COLLAPSED);
      }
    } else {
      if (body.hasClass(LEFT_PANEL_SHOW))
        body.removeClass(LEFT_PANEL_SHOW);
      else
        body.addClass(LEFT_PANEL_SHOW);
    }
  });

  // Chat View
  $(SELECTORS.SETTINGS_MENU).click(function () {
    var bodyPos = body.css('position');
    if (bodyPos != 'relative') {
      if (!body.hasClass(RIGHT_PANEL_OPEN)) {
        body.addClass(LEFT_PANEL_COLLAPSED + ' ' + RIGHT_PANEL_OPEN);
        $('.nav-bracket ul').attr('style', '');
      } else {
        body.removeClass(RIGHT_PANEL_OPEN);
        if (!$('.menutoggle').hasClass(MENU_COLLAPSED)) {
          body.removeClass(LEFT_PANEL_COLLAPSED);
          $('.nav-bracket li.active ul').css({ display: 'block' });
        }
      }
    } else {
      if (!body.hasClass(CHAT_RELATIVE_VIEW)) {
        body.addClass(CHAT_RELATIVE_VIEW);
        body.css({ left: '' });
      } else {
        body.removeClass(CHAT_RELATIVE_VIEW);
      }
    }
  });

  reposition_topnav();

  $(window).resize(function () {
    if (body.css('position') == 'relative') {
      body.removeClass(LEFT_PANEL_COLLAPSED + ' ' + RIGHT_PANEL_OPEN);
    } else {
      body.removeClass(CHAT_RELATIVE_VIEW);
      body.css({ left: '', marginRight: '' });
    }

    reposition_searchform();
    reposition_topnav();
  });

  /* This function will reposition search form to the left panel when viewed
   * in screens smaller than 767px and will return to top when viewed higher
   * than 767px
   */
  function reposition_searchform() {
    var searchform = $('.searchform');
    if (searchform.css('position') == 'relative') {
      searchform.insertBefore('.leftpanelinner .userlogged');
    } else {
      searchform.insertBefore('.header-right');
    }
  }

  reposition_searchform();

  /* This function allows top navigation menu to move to left navigation menu
   * when viewed in screens lower than 1024px and will move it back when viewed
   * higher than 1024px
   */
  function reposition_topnav() {
    var navHorizontal = $('.nav-horizontal');
    var lastNavChild = $('.nav-horizontal li:last-child');

    if (navHorizontal.length > 0) {

      // top navigation move to left nav
      // .nav-horizontal will set position to relative when viewed in screen below 1024
      if (navHorizontal.css('position') == 'relative') {

        if ($('.leftpanel .nav-bracket').length == 2) {
          navHorizontal.insertAfter('.nav-bracket:eq(1)');
        } else {
          // only add to bottom if .nav-horizontal is not yet in the left panel
          if ($('.leftpanel .nav-horizontal').length == 0)
            navHorizontal.appendTo('.leftpanelinner');
        }

        navHorizontal.css({ display: 'block' }).addClass('nav-pills nav-stacked nav-bracket');

        $('.nav-horizontal .children').removeClass('dropdown-menu');
        $('.nav-horizontal > li').each(function () {
          $(this).removeClass('open');
          $(this).find('a').removeAttr('class');
          $(this).find('a').removeAttr('data-toggle');
        });

        if (lastNavChild.has('form')) {
          lastNavChild.find('form').addClass('searchform').appendTo('.topnav');
          lastNavChild.hide();
        }

      } else {
        // move nav only when .nav-horizontal is currently from leftpanel
        // that is viewed from screen size above 1024
        if ($('.leftpanel .nav-horizontal').length > 0) {

          navHorizontal.removeClass('nav-pills nav-stacked nav-bracket').appendTo('.topnav');
          $('.nav-horizontal .children').addClass('dropdown-menu').removeAttr('style');
          lastNavChild.show();
          $('.searchform').removeClass('searchform').appendTo('.nav-horizontal li:last-child .dropdown-menu');
          $('.nav-horizontal > li > a').each(function () {
            $(this).parent().removeClass(NAV_ACTIVE);
            if ($(this).parent().find('.dropdown-menu').length > 0) {
              $(this).attr('class', 'dropdown-toggle');
              $(this).attr('data-toggle', 'dropdown');
            }
          });
        }
      }
    }
  }

  // Check if leftpanel is collapsed
  if (body.hasClass(LEFT_PANEL_COLLAPSED))
    $('.nav-bracket .children').css({ display: '' });

  // Handles form inside of dropdown
  $('.dropdown-menu').find('form').click(function (e) {
    e.stopPropagation();
  });
};

angular.module('accordion', ['ngAnimate'])

  .directive('accordion', () => ({
    scope: true,
    link: function (scope, element, attrs) {

      // Index of the accordion currently opened (-1 for none)
      let open = attrs.open || -1;

      /**
       * Open of close a given accordion.
       * @param index Index of the accordion to toggle
       */
      scope.toggleAccordion = function (index) {
        open = (open == index ? -1 : index);
      };

      /**
       * @param index Index of the accordion
       * @returns {boolean} true if the given accordion is open.
       */
      scope.isAccordionOpen = function (index) {
        return index == open;
      };
    }
  }))

  .animation('.accordion-body', function () {
    const NG_HIDE_CLASS = 'ng-hide';
    return {
      beforeAddClass: function (element, className, done) {
        if (className === NG_HIDE_CLASS) {
          element.slideUp(done);
        }
      },
      removeClass: function (element, className, done) {
        if (className === NG_HIDE_CLASS) {
          element.hide().slideDown(done);
        }
      }
    }
  });
angular.module('progressbar', [])

  .directive('progressbar', function () {
    return {
      scope: {
        value: '='
      },
      replace: true,
      transclude: true,
      template: '<div class="progress" style="margin:0;"><div class="progress-bar" ng-transclude ng-style="{width:value+\'%\'}"></div></div>'
    }
  });
angular.module('cancelButton', [])

  .directive('cancelButton', function () {
    return {
      scope: {
        cancel: '=',
        index: '='
      },
      template: '<div class="progress-cancel progress-text" ng-click="cancel(index); $event.stopPropagation();" uib-tooltip="{{\'cancel\' | translate}}"><i class="fa fa-times"></i></div>'
    };
  });
angular.module('twoLines', [])

  .directive('twoLines', () => {
    return {
      scope: {
        target: '='
      },
      replace: true,
      template: `
<div class="hashValue">
{{ target.slice(0, target.length / 2) }}
<br>
{{ target.slice(-(target.length / 2)) }}
</div>`,
    };
  });

angular.module('identity', [])

  .directive('identity', () => {
    return {
      scope: {
        target: '='
      },
      replace: true,
      template: `
<div class="identityValue">
{{ target }}
</div>`,
    };
  });

angular.module('tagsInput', [])

  .directive('tagsInput', (tagsLogic) => {
    return {
      restrict: 'E',
      templateUrl: '/resources/templates/tagsInput.html',
      scope: {
        'tags': '=',
        'placeholder': '=',
        'iconClass': '=',
        'tagChange': '&'
      },
      link: tagsLogic
    }
  })

  .factory('tagsLogic', () => {
    return function (scope, element, attrs) {
      scope.isKeySubmit = function (e) {

        // Safari does not support e.code yet
        if (!e.code) {
          switch (e.keyCode) {
            case 13:
              e.code = "Enter";
              break;
            case 32:
              e.code = "Space";
              break;
          }
        }

        // Add a new tag if 'Enter' or 'Space' is pressed
        if (e.code === "Enter" || e.code === "Space") addTag();
      };

      function addTag() {

        // Sanity check
        if (!scope.tags) {
          console.error("tags not defined")
          return;
        }

        // Prevent any tag to contain comma
        if (/,/.test(scope.newTag)) scope.newTag = scope.newTag.split(',').join('')

        // Add new tag to list of tags
        if (scope.newTag && scope.newTag !== "") scope.tags.push(scope.newTag);

        // Reset new tag in scope
        scope.newTag = undefined;

        // Call tag change listener if any
        if (scope.tagChange) scope.tagChange();
      }

      scope.removeTag = function (tag) {

        // Sanity check
        if (!scope.tags) {
          console.error("tags not defined")
          return;
        }

        // Search for tag, return if not found
        let tagIndex = scope.tags.indexOf(tag);
        if (tagIndex == -1) return;

        // Remove tag from array of tags
        scope.tags.splice(tagIndex, 1);

        // Call tag change listener if any
        if (scope.tagChange) scope.tagChange();
      };
    }
  });
angular.module('listFooters', [])

  .factory('sharedLink', () => {
    return function (scope, element, attrs) {

      if (!scope.renew) console.warn('renew listener is not set');
      if (!scope.pageSize) console.warn('pageSize parameter is not set');
      if (!scope.pagination) console.warn('pagination parameter is not set');

      /** @type {{number:number, size:number, numberOfElements: number, last: boolean, totalElements?: number}} */
      scope.pagination = scope.pagination || { number: 0, last: true, numberOfElements: 0, size: 0 };

      /** @type {{options:number[], selected: number}} */
      if (!scope.pageSize) {
        scope.pageSize = {};
      }

      if (!scope.pageSize.options) {
        scope.pageSize.options = [5, 10, 20, 50, 100];
      }

      scope.pageSize.selected = scope.pageSize.selected || 10;

      // Compute a fake number of total elements used by the pager: the user can go to the next page only if the
      // last element displayed is not the last one, so if we are not on the last page, always add 1
      scope.fakeTotalElements = function () {
        if (!scope.pagination) return 0;
        return (scope.pagination.number - 1) * scope.pageSize.selected + scope.pagination.numberOfElements + (scope.pagination.last ? 0 : 1);
      };

      scope.pageSizeChange = function () {
        return scope.renew && scope.renew(true);
      };

      scope.pageChange = function () {
        return scope.renew && scope.renew(false);
      };
    }
  })

  .directive('listFooterPager', function (sharedLink) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: {
        'pagination': '=',
        'pageSize': '=',
        'renew': '=',
      },
      templateUrl: '/resources/templates/listFooterPagerTemplate.html',
      link: sharedLink
    };
  })

  .directive('listFooterPagination', function (sharedLink) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      templateUrl: '/resources/templates/listFooterPaginationTemplate.html',
      scope: {
        'pagination': '=',
        'pageSize': '=',
        'renew': '=',
      },
      link: sharedLink
    };
  });

"use strict";

/**
 * @namespace angular
 */

function getNavigatorLanguage() {
  // navigator.languages returns something like ["en-US", "en", "fr-FR", "fr"]
  // navigator.language return something like "fr"
  // we want an array of languages like ["en", "fr"]
  const languages = (navigator.languages || [])
    .concat(navigator.language || navigator.userLanguage)
    .map((e) => e.split('-')[0]) // keep only the string before "-"
    .filter((e, i, arr) => arr.indexOf(e) == i) // drop doubles
    .filter(lang => lang == 'fr' || lang == 'en'); // drop unsupported languages

  return languages.length > 0 ? languages[0] : 'en';
}

angular.module('translation', ['pascalprecht.translate', 'ngSanitize', 'angular-momentjs'])
  .config(["$translateProvider", (provider) => {
    provider.useStaticFilesLoader({
      prefix: '/resources/modules/translation/',
      suffix: '.json'
    });

    provider.preferredLanguage(getNavigatorLanguage());
    provider.fallbackLanguage('en');

    // This strategy escapes html within translation strings. It yields a warning when disabled.
    //provider.useSanitizeValueStrategy('escape');
  }])
  .factory('translationConfig', ($translate, $moment) => {

    // List of languages currently supported by the app
    const supportedLanguages = ['fr', 'en'];

    // Language set in the browser
    const navigatorLanguage = getNavigatorLanguage();

    // Set default app language to browser language
    let currentLanguage = navigatorLanguage;

    /**
     * Given a requested language, return the fallback language to use.
     * @param {key} requested language
     * @returns {string} fallback language
     */
    const getFallbackLanguage = (key) => supportedLanguages.indexOf(key) != -1 ? key : navigatorLanguage;

    return {
      getSupportedLanguages: () => supportedLanguages,
      getNavigatorLanguage,
      getCurrentLanguage: () => currentLanguage,
      setLanguage: (key, cb) => {
        // callback with supported lang
        key = getFallbackLanguage(key);
        $translate.use(key);
        $moment.locale(key);
        currentLanguage = key;
        if (cb) cb(key);
      },
      changeLanguage: (key, cb) => {
        currentLanguage = key;
        // callback with supported language
        if (cb) cb(getFallbackLanguage(key));
      }
    }
  })
  .factory('translate', ($filter) => $filter('translate'));
"use strict";

/**
 * @namespace angular
 */

/**
 * @typedef {Object}        ValidationAnchor
 * @typedef {Number}        ValidationAnchor.created
 * @typedef {Number}        ValidationAnchor.lastModified
 * @typedef {Number}        ValidationAnchor.timestamp
 * @typedef {String}        ValidationAnchor.status
 * @typedef {String}        ValidationAnchor.hash
 * @typedef {String}        ValidationAnchor.signedHash
 * @typedef {String}        ValidationAnchor.signature
 * @typedef {String}        ValidationAnchor.name
 * @typedef {String}        ValidationAnchor.id
 * @typedef {Boolean}       ValidationAnchor.public
 * @typedef {Array<String>} ValidationAnchor.tags
 * @typedef {Array<Object>} ValidationAnchor.metadata
 */

/**
 * @typedef {Object} ValidationTransaction
 * @typedef {String} ValidationTransaction.tx_id
 * @typedef {Number} ValidationTransaction.confirmations
 * @typedef {Number} ValidationTransaction.confirmedAt
 * @typedef {String} ValidationTransaction.blockHash
 * @typedef {String} ValidationTransaction.opReturn
 */

/**
 * @typedef {Object} HashedFile
 * @typedef {String} HashedFile.name
 * @typedef {String} HashedFile.hash
 * @typedef {Number} HashedFile.progress
 */

/**
 * @typedef {Object} IdentityVerificationElement
 * @typedef {String} IdentityVerificationElement.C  country
 * @typedef {String} IdentityVerificationElement.CN domain
 * @typedef {Number} IdentityVerificationElement.L  locality
 * @typedef {Number} IdentityVerificationElement.O  organisation
 * @typedef {Number} IdentityVerificationElement.ST state
 */

/**
 * @typedef {Object} IdentityVerificationCertificate
 * @typedef {IdentityVerificationElement} IdentityVerificationCertificate.subject
 * @typedef {IdentityVerificationElement} IdentityVerificationCertificate.issuer
 */

/**
 * @typedef {Object} IdentityVerificationStatus
 * @typedef Array<IdentityVerificationCertificate> IdentityVerificationStatus.certificates
 * @typedef {String} IdentityVerificationStatus.code
 * @typedef {String} IdentityVerificationStatus.text
 * @typedef {Object} IdentityVerificationStatus.identity
 * @typedef {Object} IdentityVerificationStatus.signedIdentity
 */

/**
 * @typedef {Object} ReceiptValidation
 * @typedef {String} ReceiptValidation.code
 * @typedef {String} ReceiptValidation.text
 * @typedef {Number} ReceiptValidation.timestamp
 * @typedef {Number} ReceiptValidation.confirmations
 * @typedef {IdentityVerificationStatus} ReceiptValidation.identityVerificationStatus
 */

/**
 * @typedef {Object}                ValidationData
 * @typedef {ValidationAnchor}      ValidationData.anchor
 * @typedef {Receipt}               ValidationData.receipt
 * @typedef {ValidationTransaction} ValidationData.validation
 * @typedef {AlertInstance}         ValidationData.alert
 * @typedef {boolean}               ValidationData.hide
 */

/**
 * @typedef {Object}            ValidationContext
 * @typedef {HashedFile}        ValidationContext.file
 * @typedef {ValidationData}    ValidationContext.userData
 * @typedef {ValidationData[]}  ValidationContext.serverData
 */

/**
 * @typedef {Object}   Receipt
 * @typedef {Object}   Receipt.header
 * @typedef {String}   Receipt.header.chainpoint_version
 * @typedef {String}   Receipt.header.hash_type
 * @typedef {String}   Receipt.header.merkle_root
 * @typedef {String}   Receipt.header.tx_id
 * @typedef {Number}   Receipt.header.timestamp
 * @typedef {Object}   Receipt.signature
 * @typedef {String}   Receipt.signature.signedHash
 * @typedef {String}   Receipt.signature.pubKey
 * @typedef {String}   Receipt.signature.signature
 * @typedef {String}   Receipt.signature.identityURL
 * @typedef {Object}   Receipt.target
 * @typedef {String}   Receipt.target.target_hash
 * @typedef {String}   Receipt.target.target_URI
 * @typedef {Object[]} Receipt.target.target_proof
 */

/**
 * @typedef object     PrunedBranch
 * @typedef string     PrunedBranch.left
 * @typedef string     PrunedBranch.right
 */

/**
 * @typedef object     AnchorV2
 * @typedef string     AnchorV2.type
 * @typedef string     AnchorV2.sourceId
 */

/**
 * @typedef object          ReceiptV2
 * @typedef string          ReceiptV2.@context
 * @typedef string          ReceiptV2.type
 * @typedef string          ReceiptV2.MerkleRoot
 * @typedef string          ReceiptV2.targetHash
 * @typedef PrunedBranch[]  ReceiptV2.proof
 * @typedef AnchorV2[]      ReceiptV2.anchors
 */

angular.module('checkFileTemplate', ['api', 'twoLines', 'identity', 'accordion', 'translation'])

  .directive('checkFileElement', (api, getReceiptFileHash, getReceiptTargetHash, getReceiptTxId) => ({
    restrict: 'E',
    templateUrl: '/resources/modules/checkFile/checkFileElementTemplate.html',
    scope: {
      'element': '=',
      'name': '='
    },
    link: function (scope) {

      scope.getReceiptFileHash = getReceiptFileHash;
      scope.getReceiptTargetHash = getReceiptTargetHash;
      scope.getReceiptTxId = getReceiptTxId;

      scope.$watch('element', setElementValues, true);

      /**
       * @param {{alert: AlertInstance, receipt: Receipt, anchor: ValidationAnchor, validation: ValidationTransaction}} element
       */
      function setElementValues(element) {

        /** @type {AlertInstance} */
        scope.alert = element.alert;

        /** @type {Receipt} */
        const receipt = scope.receipt = element.receipt;

        /** @type {ValidationAnchor} */
        const anchor = scope.anchor = element.anchor;

        /** @type {ValidationTransaction} */
        scope.validation = element.validation;

        scope.signature = (receipt && receipt.signature) || null;

        /** @type {IdentityVerificationStatus} */
        scope.identityVerificationStatus = scope.validation ? scope.validation.identityVerificationStatus : null;
        if (scope.identityVerificationStatus) {
          scope.identity = scope.identityVerificationStatus.identity;
          scope.signedIdentity = scope.identityVerificationStatus.signedIdentity;
        }
      }

      scope.getFullIdentityURL = () => scope.signature &&
        (scope.signature.identityURL
          + '?pubKey=' + scope.signature.pubKey
          + (scope.signature.signedIdentity ?
            '&signedIdentity=' + encodeURIComponent(scope.signature.signedIdentity) : "")
          + '&leftData=random');

      scope.downloadReceipt = () => {
        if (!scope.anchor) return;
        api.anchors.downloadReceipt(scope.anchor);
      };

      scope.downloadReceiptFromJSON = () => {
        if (!scope.receipt) return;

        // The receipt name is (in order of preference):
        // - the anchor name (if an anchor object is available in the scope)
        // - the <check-file-element> name attribute
        // - the hash of the file
        const name = (scope.anchor && scope.anchor.name) || scope.name || getReceiptFileHash(scope.receipt);
        api.downloadJSON(name + '_proof_receipt.json', scope.receipt);
      };

      scope.platform = api.env.platform();
    }
  }))

  .directive('checkFileElementList', () => ({
    restrict: 'E',
    templateUrl: '/resources/modules/checkFile/checkFileElementListTemplate.html',
    scope: {
      'data': '=',
      'show': '=',
      'name': '=',
      'open': '='
    },

    link: function (scope) {

      // Get the type of alert to display an element
      scope.getType = (element) => {
        return (
          element.validation
          && element.validation.identityVerificationStatus
          && element.validation.identityVerificationStatus.code !== 'VERIFIED' ?
            'warning' : element.alert.type
        ) || 'info';
      };
    }
  }))

  .factory('getReceiptTargetHash', () => (receipt) => {
    if (!receipt) return null;
    return (receipt.target && receipt.target.target_hash.toLowerCase()) // Chainpoint v1
      || receipt.targetHash.toLowerCase(); // Chanpoint v2
  })

  .factory('getReceiptFileHash', () => (receipt) => {
    if (!receipt) return null;
    return (receipt.signature && receipt.signature.signedHash) // Signature receipt
      || (receipt.target && receipt.target.target_hash.toLowerCase()) // Chainpoint v1
      || receipt.targetHash.toLowerCase(); // Chainpoint v2
  })

  .factory('getReceiptTxId', () => (receipt) => {
    if (!receipt) return null;
    if (receipt.header) {
      return receipt.header.tx_id || null;
    } else {
      return receipt.anchors && receipt.anchors.length && receipt.anchors.find((a) => a.type === 'BTCOpReturn').sourceId || null;
    }
  })

  .factory('getValidationStatus', () =>

    /**
     * @param {ReceiptValidation} validation
     * @returns {string}
     */
    function getValidationStatus(validation) {
      switch (validation.code) {
        case 'VERIFIED':
          if (validation.identityVerificationStatus
            && validation.identityVerificationStatus.code !== 'VERIFIED')
            return 'warning';
          return 'success';
        case 'TX_NOT_CONFIRMED':
        case 'TX_NOT_SENT':
          return 'warning';
        default:
          return 'danger';
      }
    })

  .factory('getValidationMessage', ($filter, translate) =>

    /**
     * @param {Receipt} receipt
     * @param {ReceiptValidation} validation
     * @returns {string}
     */
    function getValidationMessage(receipt, validation) {
      if (validation.code === 'VERIFIED') {
        if (!receipt.signature)
          return translate("timestamped_on") + ' ' + $filter('time')(validation.timestamp);
        let message = receipt.signature.signatureRequestURL ? translate("signed_on") : translate("seald_on");
        message += ' ' + $filter('time')(validation.timestamp);
        message += ' ' + translate('by') + ' ';
        let ids = validation.identityVerificationStatus;
        if (ids && ids.signedIdentity && ids.signedIdentity.commonName) {
          message += ids.signedIdentity.commonName;
        } else if (ids && ids.identity && ids.identity.commonName) {
          message += ids.identity.commonName;
        } else if (ids && ids.certificates && ids.certificates.length) {
          let cert = ids.certificates[0];
          message += cert.subject.O || cert.subject.CN;
        } else
          message += translate('the_bitcoin_address') + ' ' + receipt.signature.pubKey;
        return message;
      } else {
        return translate(validation.code.toLowerCase());
      }
    });

"use strict";

/**
 * @namespace angular
 */

// include with <script src="/build/qrcode.js"></script>
angular.module('qrcode', [])
  .directive('qrcode', ($window) => {

    const QRCode = $window.QRCode;

    const options = {
      margin: 0,
      color: {
        dark: '#000',
        light: '#fff0'
      }
    };

    function draw(element, data, extraOptions) {
      if (!data) return;
      QRCode.toCanvas(element, data, Object.assign({}, options, extraOptions), (error, canvas) => {
        if (error) console.log('Error', error);
      });
    }

    function link(scope, element) {
      scope.$watch('data', (value) => draw(element[0], value, { width: scope.width || undefined }))
    }

    return {
      restrict: 'E',
      replace: true,
      scope: {
        data: '=',
        width: '@'
      },
      link: link,
      template: '<canvas></canvas>'
    };

  });

"use strict";

/**
 * @namespace angular
 */

// Simple libphonenumber wrapper to use it as a module in angularjs
angular.module('libphonenumber', [])
  .factory('libphonenumber', ($window) => {
    return $window.libphonenumber;
  });

'use strict';

/**
 * Display service unavailable page
 * @param e Error
 */
function displayServiceUnavailable(e) {
  const gid = (id) => document.getElementById(id);
  let message;

  if (e.status) {
    switch (e.status) {
      case 502:
      case 503:
        message = 'Sorry, the Woleet service is currently unavailable.';
        break;
      case 500:
      default:
        message = 'Sorry, something went wrong with the Woleet service:<br>'
          + '<code>'
          + 'error ' + e.status + ': '
          + (e.data.message || e.data.exception || e.data.apiException || e.data.serviceException || e.data.error)
          + '</code>'
        ;
        break;
    }
  }

  gid("service-error").style.display = "flex";
  gid("service-error-msg").innerHTML = message;
}

app.filter('time', function ($moment, api) {

  let timezone = api.user.preferences('timezone') || $moment.tz.guess();
  $moment.tz.setDefault(timezone);

  function timeFilter(time, format) {
    let moment = $moment(parseInt(time));

    // If the parameter cannot be parsed as valid date, return it as provided.
    if (!moment.isValid())
      return time;

    return moment
      .locale(api.user.preferences('lang') || 'en')
      .format(format || 'lll');
  }

  // Always reevaluate
  timeFilter.$stateful = true;

  return timeFilter;
});

app.filter('cap', () => (s) => (s && s.length) ? s.charAt(0).toUpperCase() + s.slice(1) : '');
app.filter('currency', function (api) {

  function currencyFilter(amount) {
    return amount.toLocaleString(
      api.user.preferences('lang') || 'en',
      { style: "currency", currency: "EUR", maximumFractionDigits: 2 }
    );
  }

  // Always reevaluate
  currencyFilter.$stateful = true;

  return currencyFilter;
});
